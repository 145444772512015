import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BtnA = styled.a`
  font-weight: 500;
  align-self: flex-start;
  display: block;
  position: relative;
  color: ${props => props.color_font || props.theme.color.whiteb};
  background-color: ${props => props.color || props.theme.color.base1};
  text-decoration: none;
  padding: 0.4rem 2rem;
  margin-top: ${props => props.mt_a || "2rem"};
  margin-bottom: ${props => props.mb_a || "2rem"};
  transition: all 0.4s ease-in-out;

  b {
    color: transparent;
  }

  &:hover {
    color: ${props => props.color_font_hover_a || props.theme.color.grey2};
    background-color: ${props =>
      props.color_hover_a || props.theme.color.grey8};
    ::before {
      background-color: ${({ theme }) => theme.color.grey3};
      border: 2px solid ${({ theme }) => theme.color.grey3};
    }
  }
`

const BtnL = styled(Link)`
  font-weight: 500;
  color: ${props => props.color_font || props.theme.color.whiteb};
  background-color: ${props => props.color || props.theme.color.base1};
  position: relative;
  align-self: flex-start;
  display: block;
  padding: 0.4rem 2rem;
  margin-top: ${props => props.mt || "2rem"};
  margin-bottom: ${props => props.mb || "2rem"};
  transition: all 0.8s;

  b {
    color: transparent;
  }

  &:hover {
    color: ${props => props.color_font_hover || props.theme.color.grey2};
    background-color: ${props => props.color_hover || props.theme.color.grey8};

    ::before {
      background-color: ${({ theme }) => theme.color.grey3};
      border: 2px solid ${({ theme }) => theme.color.grey3};
    }
  }
`

const BtnB = styled.button`
  font-weight: 500;
  color: ${props => props.color_font || props.theme.color.whiteb};
  background-color: ${props => props.color || props.theme.color.base1};
  position: relative;
  align-self: flex-start;
  display: block;
  padding: 0.4rem 2rem;
  margin-top: ${props => props.mt || "2rem"};
  margin-bottom: ${props => props.mb || "2rem"};
  transition: all 0.8s;

  b {
    color: transparent;
  }

  &:hover {
    color: ${props => props.color_font_hover || props.theme.color.grey2};
    background-color: ${props => props.color_hover || props.theme.color.grey8};

    ::before {
      background-color: ${({ theme }) => theme.color.grey3};
      border: 2px solid ${({ theme }) => theme.color.grey3};
    }
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.color.font};
  }
`

const Button = ({
  children,
  link,
  href,
  target,
  state,
  onClick,
  color,
  colorHover,
  colorFont,
  colorFontHover,
}) => (
  <>
    {link ? (
      <BtnL
        color={color}
        color_hover={colorHover}
        color_font={colorFont}
        color_font_hover={colorFontHover}
        to={link}
      >
        {children}
      </BtnL>
    ) : href ? (
      <BtnA
        color_a={color}
        color_hover_a={colorHover}
        color_font_a={colorFont}
        color_font_hover_a={colorFontHover}
        href={href}
        target={target}
      >
        {children}
      </BtnA>
    ) : (
      <BtnB
        color_btn={color}
        color_hover_btn={colorHover}
        color_font_btn={colorFont}
        color_font_hover_btn={colorFontHover}
        onClick={onClick}
        state={state}
      >
        {children}
      </BtnB>
    )}
  </>
)

export default Button
