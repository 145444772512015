import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { StaticImage } from "gatsby-plugin-image"
//DATA
//STYLES
import { H1, H4, H3, LabelBW, Text } from "../utils/typo"
import {
  Container,
  Section,
  CardWrapperFull,
  ContainerHalf,
  Pattern,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Button from "../components/button/Button"

const BanerInfo = styled(ContainerHalf)`
  padding-top: 5rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${down("xs")} {
    padding-bottom: 2rem;
  }
`

const BanerFoto = styled(CardWrapperFull)`
  position: relative;
  top: 0;
`

const Important = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: -7rem;
  position: relative;
  z-index: 1;

  ${down("xs")} {
    grid-template-columns: 1fr;
  }

  div:nth-of-type(2) {
    background-color: ${props => props.theme.color.base1};
    padding: 1.5rem;

    div {
      display: flex;
      justify-content: space-between;
    }

    p,
    h3 {
      color: ${props => props.theme.color.whiteb};
      margin-bottom: 2.5rem;
    }

    h3 {
      line-height: 1.2;
      font-weight: 800;
    }
  }
`

const Lines1 = styled(Pattern)`
  height: ${props => props.height || "auto"};
  position: relative;
  padding-bottom: 200px;
  margin-bottom: -200px;
`

const IndexPage = () => {
  return (
    <div>
      <Seo title="Home" slug="" />
      <Section>
        <BanerFoto xl="2" l="2" m="2">
          <BanerInfo>
            <LabelBW as="h1" mb="2rem">
              Therm check - system mierzenia temperatury
            </LabelBW>
            <H1
              as="h2"
              xl="65px"
              l="60px"
              m="55px"
              s="50px"
              xs="45px"
              lineHeight="1.1"
            >
              Pomiar temperatury -
              <br /> bezdotykowy i szybki
            </H1>
            <H4 as="h3" weight="300" mt="2rem">
              Niezawodne wykrywanie stanów gorączkowych
              <br />
              na skalę przemysłową.
            </H4>
            <Button link="/oferta">Dowiedz się więcej</Button>
          </BanerInfo>
          <StaticImage
            placeholder="blurred"
            alt="Zdjęcie promocyjne pokazujące pomiar temperatury"
            src="../assets/images/TC2.png"
            layout="fullWidth"
            objectFit="cover"
          />
        </BanerFoto>
        <Lines1>
          <Container>
            <Important>
              <div></div>
              <div>
                <H3>
                  Zadbaj o bezpieczeństwo swoich klientów i pracowników,
                  weryfikując temperaturę w sposób bezdotykowy.!
                </H3>

                <Text>
                  Oferujemy system szybkiego, bezdotykowego mierzenia
                  temperatury z wykorzystaniem termowizji. Nasza aplikacja
                  dzięki wykorzystaniu sztucznej inteligencji oferuje
                  najdokładniejszą metodę pomiaru temperatury z powierzchnii
                  oka.
                </Text>
              </div>
            </Important>
          </Container>
        </Lines1>
      </Section>
    </div>
  )
}
export default IndexPage
